function token() {
  let tok = '';
  return {
    setToken: t => {
      tok = t;
    },
    getToken: () => tok,
    resetToken: () => {
      tok = '';
    },
  };
}

export default token();
