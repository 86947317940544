import * as actionTypes from '../constants/accountActionsTypes';

const initialState = {
  account: null,
  isStarted: true,
  // isLoaded
  isLoad: false,
  isError: false,
  msg: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case actionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        account: action.payload,
      };
    case actionTypes.FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
        account: null,
        msg: action.payload,
      };
    case actionTypes.ACCOUNT_CLEAR_STORE:
      return {
        account: null,
        isStarted: false,
        isLoad: false,
        isError: false,
        msg: null,
      };
    default:
      return state;
  }
}
