import React, { Component } from 'react';
import createDAO from './DAO';
import api from '../config/api';

class UsersDAO extends createDAO {
  constructor() {
    super({ baseUrl: api.ms.users });
    const parent = this.public;

    this.public = {
      getUsers: ({ ...options }) => parent.get({ url: '/users', ...options }),

      getUserById: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.get({ url: `/users/${id}`, ...options });
      },

      createUser: ({ ...options }) => parent.post({ url: '/users', ...options }),

      updateUser: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.put({ url: `/users/${id}`, ...options });
      },

      deleteUser: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.delete({ url: `/users/${id}`, ...options });
      },

      whoAmI: ({ ...options }) => parent.get({ url: '/account', ...options }),

      getRoles: ({ ...options }) => parent.get({ url: '/roles', ...options }),

      getRoleById: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.get({ url: `/roles/${id}`, ...options });
      },

      createRole: ({ ...options }) => parent.post({ url: '/roles', ...options }),

      updateRole: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.put({ url: `/roles/${id}`, ...options });
      },

      deleteRole: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.delete({ url: `/roles/${id}`, ...options });
      },

      getRolePermissions: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.get({ url: `/roles/${id}/permissions`, ...options });
      },

      updateRolePermissions: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.put({ url: `/roles/${id}/permissions`, ...options });
      },

      confirmUser: ({ ...options }) => parent.post({ url: '/account/confirm', ...options }),

      confirmEmail: ({ ...options }) => parent.post({ url: '/account/confirm-email', ...options }),

      changePassword: ({ ...options }) =>
        parent.post({ url: '/account/change-password', ...options }),

      changeEmail: ({ ...options }) => parent.put({ url: '/account/change-email', ...options }),

      changeAccount: ({ ...options }) => parent.put({ url: '/account', ...options }),

      forgotPassword: ({ ...options }) =>
        parent.post({ url: '/account/forgot-password', ...options }),

      resetPassword: ({ ...options }) =>
        parent.post({ url: '/account/reset-password', ...options }),

      getPermissions: ({ ...options }) => parent.get({ url: '/permissions', ...options }),

      getPermissionById: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.get({ url: `/permissions/${id}`, ...options });
      },

      createPermission: ({ ...options }) => parent.post({ url: '/permissions', ...options }),

      updatePermission: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.put({ url: `/permissions/${id}`, ...options });
      },

      deletePermission: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.delete({ url: `/permissions/${id}`, ...options });
      },

      getPermissionRoles: ({ id, ...options }) => {
        if (!id) return Promise.reject(new Error('id is required'));
        return parent.get({ url: `/permissions/${id}/roles`, ...options });
      },
    };
  }
}

const DAO = new UsersDAO();

export const Users = DAO.public;

export default WrappedComponent =>
  class UserDAO extends Component {
    render() {
      return <WrappedComponent {...this.props} usersDAO={Users} />;
    }
  };
