// import { SET_CURRENT_USER } from '../actions/user.actions';

const initialState = {
  name: 'name',
  totalStuff: 0,
};

function user(state = initialState, action) {
  switch (action.type) {
    // case SET_CURRENT_USER:
    //   return {
    //     ...state,
    //     ...action.data,
    //   };
    default:
      return state;
  }
}

export default user;
