import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    languages: ['en-US', 'de-DE'],
    loadPath: '/public/locales/{{lng}}/translation.json',
    // path to post missing resources
    addPath: '/public/locales/{{lng}}/translation.missing.json',
    saveMissingTo: 'all',
    fallbackLng: 'en',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupCookie: 'lang',
      caches: ['cookie'],
      checkWhitelist: true,
    },
    saveMissing: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    // whitelist: ['en-US', 'de-DE'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { wait: true },
  });

export default i18n;
