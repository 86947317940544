import React from 'react';

const Account = React.lazy(() => import('./Account/Account'));
const Modules = React.lazy(() => import('./Modules/Modules'));
const Login = React.lazy(() => import('./Login/Login'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword/ForgotPassword'));
const ConfirmUser = React.lazy(() => import('./EmailPages/ConfirmUser'));
const RestorePassword = React.lazy(() => import('./EmailPages/RestorePassword'));
const ConfirmEmail = React.lazy(() => import('./EmailPages/ConfirmEmail'));

export default [
  {
    path: '/',
    component: Account,
    title: 'Account',
    exact: true,
    protect: true,
    hidden: false, // show in nav
  },
  {
    path: '/modules',
    component: Modules,
    title: 'Modules',
    exact: true,
    protect: true,
    hidden: false, // show in nav
  },
  {
    path: '/login',
    component: Login,
    title: 'Login',
    exact: true,
    protect: false,
    hidden: true, // hide from navi18n.t()
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    title: 'Forgot Password',
    exact: true,
    protect: false,
    hidden: true, // hide from nav
  },
  {
    path: '/confirm-account',
    component: ConfirmUser,
    title: 'Confirm User',
    exact: true,
    protect: false,
    hidden: true, // hide from nav
  },
  {
    path: '/restore-password',
    component: RestorePassword,
    title: 'Restore Password',
    exact: true,
    protect: false,
    hidden: true, // hide from nav
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
    title: 'Confirm Email',
    exact: true,
    // protect: false,
    forAll: true,
    hidden: true, // hide from nav
  },
  {
    path: '*',
    component: NotFound,
    title: 'Not Found',
    protect: false,
    hidden: true, // hide from nav
  },
];
