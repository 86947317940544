import React, { Component } from 'react';
import axios from 'axios';
import Token from './token';
import api from '../config/api';

class StsDAO {
  constructor() {
    this.client = axios.create({
      baseURL: `${api.ms.sts}`,
    });

    this.client.interceptors.response.use(response => {
      const redirectUrl = response.headers['x-redirect-to'];
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      return response;
    });

    this.public = {
      login: ({ login, password }, options) => {
        // put redirect: 'no_redirect' if you don't want to redirect automatically

        const redirectParam = options && options.redirect ? `?redirect=${options.redirect}` : '';
        return this.client.post(`/login${redirectParam}`, {
          login,
          password,
        });
      },

      backToOrigin: () => this.client.get('/login/back-to-origin'),

      logout: () => this.client.get('/logout'),

      logoutAll: ({ ...options }) => this.client.get('/logout-all', { ...options }),

      refresh: () => this.client.get('/refresh'),

      refreshToken: () =>
        this.client
          .get('/refresh')
          .then(res => {
            const { access_token } = res.data.data;
            Token.setToken(access_token);
            return true;
          })
          .catch(() => false),
    };
  }
}

const DAO = new StsDAO();

export const Sts = DAO.public;

export default WrappedComponent =>
  class StsHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} stsDAO={Sts} />;
    }
  };
