import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Switch, BrowserRouter, withRouter } from 'react-router-dom';
import { notification } from 'antd';

import 'antd/dist/antd.min.css';
// polyfills
import 'babel-polyfill';
import 'url-search-params-polyfill';
import i18n from './i18n';

import { loadAccount, clearAccount } from './redux/controllers/account.controller';

import PageSpinner from './components/Spinner/PageSpinner';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UnAuthRoute from './components/UnAuthRoute/UnAuthRoute';
import OpenRoute from './components/OpenRoute/OpenRoute';

import routes from './pages';
import './App.less';
import './theme.less';

@connect(
  store => ({
    account: store.account.account,
    isLoad: store.account.isLoad,
    isError: store.account.isError,
    isStarted: store.account.isStarted,
  }),
  {
    loadAccount,
    clearAccount,
  },
)
@withRouter
class App extends Component {
  componentDidMount() {
    this.props.loadAccount();
    const search = new URLSearchParams(this.props.location.search);
    const message = search.get('error-message');
    if (!message) return null;
    return notification.error({
      message,
      duration: 10,
      onClose: () => {
        // remove query
        window.history.replaceState(null, null, `${this.props.location.pathname}`);
      },
    });
  }
  componentWillUnmount() {
    this.props.clearAccount();
  }

  render() {
    const { isStarted, isLoad } = this.props;
    if (isStarted && !isLoad) {
      return <PageSpinner msg={i18n.t('Loading...')} />;
    }
    return (
      <BrowserRouter>
        <Suspense fallback={<PageSpinner msg={i18n.t('Loading...')} />}>
          <Switch>
            {routes.map(route => {
              if (route.protect) {
                return (
                  <ProtectedRoute
                    key={route.path}
                    staticContext={this.props.context}
                    {...this.props}
                    {...route}
                  />
                );
              } else if (!route.protect && !route.forAll) {
                return (
                  <UnAuthRoute
                    key={route.path}
                    staticContext={this.props.context}
                    {...this.props}
                    {...route}
                  />
                );
              } else if (route.forAll) {
                return (
                  <OpenRoute
                    key={route.path}
                    staticContext={this.props.context}
                    {...this.props}
                    {...route}
                  />
                );
              }
              return null;
            })}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  context: propTypes.objectOf(propTypes.string),
  location: propTypes.objectOf(propTypes.string),
  loadAccount: propTypes.func,
  clearAccount: propTypes.func,
  isLoad: propTypes.bool,
  isError: propTypes.bool,
  isStarted: propTypes.bool,
};

App.defaultProps = {
  context: null,
  location: null,
  loadAccount: null,
  clearAccount: null,
  isLoad: false,
  isError: false,
  isStarted: false,
};

export default App;
