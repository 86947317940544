/*
 * this route is enabled only for unauthorized users
 * */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import propTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, ...ctx }) => (
  <Route
    {...ctx}
    render={props =>
      !_.isEmpty(ctx.account) ? (
        <Redirect
          to={{
            pathname: '/',
            search: window.location.search,
            state: {
              from: props.location, // eslint-disable-line
            },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

ProtectedRoute.propTypes = {
  component: propTypes.node.isRequired,
  account: propTypes.shape({}),
};

ProtectedRoute.defaultProps = {
  account: null,
};

export default connect(
  store => ({
    account: store.account.account,
  }),
  null,
)(withRouter(ProtectedRoute));
