import * as actions from '../actions/account.action';
import history from '../../config/history';
import { Users } from '../../DAO_hocs/usersDAO_HOC';
import { Sts } from '../../DAO_hocs/stsDAO_HOC';

const afterAccountLoadActions = [];

export const loadAccount = () => async dispatch => {
  await dispatch(actions.loadAccountStart());
  try {
    const response = await Users.whoAmI({
      params: {
        scope: ['withPermission', 'withClient', 'withAvatar', 'withLocation'],
      },
    });
    const account = response.data.data;
    if (!account) throw new Error(response.data.message);
    await dispatch(actions.loadAccountSuccess(account));
    const promises = afterAccountLoadActions.map(
      actionFunc => new Promise(resolve => resolve(dispatch(actionFunc()))),
    );
    Promise.all(promises);
  } catch (e) {
    await dispatch(actions.loadAccountFailure(e.message));
  }
};

export const clearAccount = () => async dispatch => {
  await dispatch(actions.clearAccountStore());
};

export const logout = () => async dispatch => {
  try {
    await Sts.logout();
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(clearAccount());
    history.push('/login');
  }
};

export const logoutAll = () => async dispatch => {
  try {
    await Sts.logoutAll();
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(clearAccount());
    history.push('/login');
  }
};
