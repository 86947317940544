import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import history from '../config/history';

import user from './reducers/user.reducer';
import meta from './reducers/meta.reducer';
import account from './reducers/account.reducer';
import licenses from './reducers/licenses.reducer';

const middleware = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const reducer = combineReducers({
  router: connectRouter(history),
  meta,
  user,
  account,
  licenses,
});

export default createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));
