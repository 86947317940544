export const APP_SHOW_LOADER = 'APP_SHOW_LOADER';
export const APP_HIDE_LOADER = 'APP_HIDE_LOADER';
export const SET_USERS = 'SET_USERS';
export const OPEN_PROFILE_MODAL = 'OPEN_PROFILE_MODAL';
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const APP_SUCCESS_TOAST = 'APP_SUCCESS_TOAST';
export const APP_ERROR_TOAST = 'APP_ERROR_TOAST';
export const OPEN_GO_PREMIUM_MODAL = 'OPEN_GO_PREMIUM_MODAL';
export const CLOSE_GO_PREMIUM_MODAL = 'CLOSE_GO_PREMIUM_MODAL';
export const APP_REFRESH_TOAST = 'APP_REFRESH_TOAST';

export function showLoader() {
  return {
    type: APP_SHOW_LOADER,
  };
}

export function hideLoader() {
  return {
    type: APP_SHOW_LOADER,
  };
}

export function openGoPremiumModal() {
  return dispatch => dispatch({ type: OPEN_GO_PREMIUM_MODAL });
}

export function closeGoPremiumModal() {
  return dispatch => dispatch({ type: CLOSE_GO_PREMIUM_MODAL });
}

export function openProfileModal() {
  return dispatch => dispatch({ type: OPEN_PROFILE_MODAL });
}

export function closeProfileModal() {
  return dispatch => dispatch({ type: CLOSE_PROFILE_MODAL });
}

export function showSuccessToast({ message }) {
  return dispatch => dispatch({ type: APP_SUCCESS_TOAST, message });
}

export function showErrorToast({ message }) {
  return dispatch => dispatch({ type: APP_ERROR_TOAST, message });
}

export function refreshToast() {
  return dispatch => dispatch({ type: APP_REFRESH_TOAST });
}
