import * as actionTypes from '../constants/licenseActionsTypes';

const initialState = {
  licenses: [],
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LICENSES_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case actionTypes.FETCH_LICENSES_SUCCESS:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        licenses: action.payload.licenses,
      };
    case actionTypes.FETCH_LICENSES_FAILURE:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
        licenses: [],
        msg: action.payload,
      };
    case actionTypes.LICENSES_CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}
