import axios from 'axios';
import Token from './token';
import { Sts } from './stsDAO_HOC';

const ignoredRefresh = ['change-password', 'change-email', 'change-mail'];

export default class Api {
  constructor(options = {}) {
    this.client =
      options.client ||
      axios.create({
        baseURL: options.baseUrl,
      });
    this.baseURL = options.baseURL;
    this.stsClient = Sts;

    this.client.interceptors.request.use(
      config => {
        const token = Token.getToken();
        if (!token) {
          return config;
        }

        const newConfig = {
          ...config,
        };
        newConfig.headers.Authorization = `Bearer ${token}`;
        return newConfig;
      },
      e => Promise.reject(e),
    );

    this.client.interceptors.response.use(
      r => r,
      async error => {
        if (error.response.status === 401) {
          // not refresh ignored path
          if (ignoredRefresh.some(ignorePath => error.request.responseURL.includes(ignorePath))) {
            return Promise.reject(error);
          }
          const isToken = await this.stsClient.refreshToken();
          if (isToken) {
            const newRequest = {
              ...error.config,
              baseURL: '',
              retry: true,
            };
            return this.client(newRequest);
          }
          this.appLogout();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );

    this.appLogout = () => {
      // console.log(`Exite from the app`);
      Token.setToken('');
      return this.stsClient.logout();
    };

    this.public = {
      get: ({ url = '', ...rest }) => this.client.get(url, rest),

      post: ({ url = '', ...rest }) => this.client.post(url, rest.data || rest, rest.config || {}),

      put: ({ url = '', ...rest }) => this.client.put(url, rest),

      patch: ({ url = '', ...rest }) => this.client.patch(url, rest),

      delete: ({ url = '', ...rest }) => this.client.delete(url, rest),
    };
  }
}
