/*
 * this route is enabled only for Authorized users
 * */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component: Component, ...otherProps }) {
  return (
    <Route
      {...otherProps}
      render={props =>
        !isEmpty(otherProps.account) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: window.location.search,
              state: {
                from: props.location, // eslint-disable-line react/prop-types
              },
            }}
          />
        )
      }
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  account: PropTypes.shape({}),
};

ProtectedRoute.defaultProps = {
  account: null,
};

export default connect(
  store => ({
    account: get(store, 'account.account'),
  }),
  null,
)(withRouter(ProtectedRoute));
