/*
 * this route is enabled for all users
 * */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import propTypes from 'prop-types';

const OpenRoute = ({ component: Component, ...ctx }) => (
  <Route {...ctx} render={props => <Component {...props} />} />
);

OpenRoute.propTypes = {
  component: propTypes.node.isRequired,
  account: propTypes.shape({}),
};

OpenRoute.defaultProps = {
  account: null,
};

export default connect(
  store => ({
    account: store.account.account,
  }),
  null,
)(withRouter(OpenRoute));
